var mainAside;
var originalIndex;

(function($) {
  'use strict';

  var setupAccordionControls = function() {
    $('.accordion__expand-btn').click(function() {
      var parentAccordion = $(this).closest('.accordion');
      var state = $(this).data('state');

      if (state == 'initial') {
        parentAccordion.find('.accordion__item').addClass('active');

        parentAccordion
          .find('.accordion__link')
          .addClass('uber-accordion__button-active');

        parentAccordion
          .find('.accordion__target')
          .addClass('uber-accordion__target-active');

        $(this).data('state', 'expanded');
        $(this).text('Close all');
      } else {
        parentAccordion.find('.accordion__item').removeClass('active');

        parentAccordion
          .find('.accordion__link')
          .removeClass('uber-accordion__button-active');

        parentAccordion
          .find('.accordion__target')
          .removeClass('uber-accordion__target-active');

        $(this).data('state', 'initial');
        $(this).text('Open all');
      }
    });
  };

  /* Gets index of specified node.
   */
  var getNodeIndex = function(node) {
    var index = 0;
    while ((node = node.previousElementSibling)) {
      index++;
    }
    return index;
  };

  setupAccordionControls();

  //add external link icon to content text - ticket SDNSW-3612
  // var externalIcon = function() {
  //   //var siteURL = new RegExp(location.host);
  //   $('.page__content a, footer .nav__list a').each(function() {
  //     var siteURL = new RegExp(location.host);
  //     var link = $(this).attr('href');
  //     //if its a HTTP link
  //     if (link.indexOf('http://') == 0 || link.indexOf('https://') == 0) {
  //       if (
  //         !siteURL.test(link) &&
  //         !$(this).hasClass('rsbtn_play') &&
  //         !$(this).hasClass('share__link') &&
  //         !$(this).hasClass('no-ext-ic')
  //       ) {
  //         $(this).attr('target', '_blank');
  //         $(this).addClass('external-link');
  //         $(this).append(`<span class="visuallyhidden">(new window)</span>`);
  //       }
  //     }
  //   });
  // };

  // externalIcon();

  //function to add the next and previous page names to the detailed guide
  var detailedGuideNextPrevPageNames = function() {
    // need a better way of doing this - ive just added a empty div onto the top
    // container in matrix
    if ($('.detailed-guide').length) {
      var pageCount = parseInt($('.detailed-guide__link').length - 1);
      var pagePosition = $(
        '.detailed-guide__item.selected .detailed-guide__link',
      ).data('position');
      $('.detailed-guide__link').each(function(i) {
        var itemPosition = $(this).data('position');
        var itemText = $(this)
          .find('a')
          .text();
        if (pagePosition === itemPosition) {
          $(this).addClass('current');
          $(this)
            .find('a')
            .replaceWith(
              '<span class="guide-links__link">' + itemText + '</span>',
            );
          var itemPrevPosition = parseInt(i - 1);
          var itemNextPosition = parseInt(i + 1);
          if (itemPrevPosition >= 0) {
            var itemPrevName = $('.detailed-guide__link')
              .eq(itemPrevPosition)
              .text();
            $('.link-previous').append(
              ' <span class="page-links__text">' +
                $.trim(itemPrevName) +
                '</span>',
            );
          }
          if (itemNextPosition <= pageCount) {
            var itemNextName = $('.detailed-guide__link')
              .eq(itemNextPosition)
              .text();
            $('.link-next').append(
              ' <span class="page-links__text">' +
                $.trim(itemNextName) +
                '</span>',
            );
          }
        }
      });
    }
  };

  detailedGuideNextPrevPageNames();

  const handleLinkHash = () => {
    var hash = window.location.hash;
    if (hash.length > 0) {
      linkHashHandler(hash);
    }

    $('a:not(.tag__link--clear-filters)').click(function() {
      if (!$(this).hasClass('accordion__link') && !$(this).hasClass('tabs__link')) {
        const href = $(this).attr('href');
        linkHashHandler(href);
      }
    });
  };

  const linkHashHandler = hashedId => {
    if (hashedId.indexOf('#') === 0) {
      const $hashedId = $(hashedId);
      if ($hashedId.length === 1) {
        if (
          $hashedId.hasClass('accordion__link') &&
          !$hashedId.hasClass('uber-accordion__button-active')
        ) {
          $hashedId.trigger('click');
        }

        $('html, body').animate(
          {
            scrollTop: $hashedId.offset().top - 10,
          },
          1000,
        );
      }
    } else {
      return false;
    }
  };

  handleLinkHash();

  if ($('.o-long-doc').length > 0) {
    /* Generate table of content html*/
    var tag, previousTag, title, idStr;
    var htmlStr = '';

    $('#main-content h2, #main-content h3, #main-content h4').each(function(
      index,
    ) {
      tag = $(this).prop('tagName');
      title = $(this).text();
      // idStr = title.toLowerCase().replace(/\s+/g, "-").replace("$",
      // "dollar").replace("/", "-or-");
      idStr = index;

      $(this).attr('id', idStr);

      if (tag === 'H2') {
        if (previousTag === 'H2') {
          htmlStr = htmlStr + '</li>';
        } else if (previousTag === 'H3') {
          htmlStr = htmlStr + '</li></ul>';
        } else if (previousTag === 'H4') {
          htmlStr = htmlStr + '</li></ul></li></ul>';
        }
        htmlStr =
          htmlStr +
          '<li class="toc-item toc-item__h2"><a href="#' +
          idStr +
          '">' +
          title +
          '</a>';
      }

      if (tag === 'H3') {
        if (previousTag === 'H3') {
          htmlStr = htmlStr + '</li>';
        } else if (previousTag === 'H2') {
          htmlStr = htmlStr + '<ul class="toc-list toc-list__h3" >';
        } else if (previousTag === 'H4') {
          htmlStr = htmlStr + '</li></ul>';
        }
        htmlStr =
          htmlStr +
          '<li class="toc-item toc-item__h3"><a href="#' +
          idStr +
          '">' +
          title +
          '</a>';
      }

      if (tag === 'H4') {
        if (previousTag === 'H4') {
          htmlStr = htmlStr + '</li>';
        } else if (previousTag === 'H3') {
          htmlStr = htmlStr + '<ul class="toc-list toc-list__h4" >';
        }
        htmlStr =
          htmlStr +
          '<li class="toc-item toc-item__h4"><a href="#' +
          idStr +
          '">' +
          title +
          '</a>';
      }

      previousTag = tag;
    });

    $('#toc-list').append(htmlStr);

    //scroll to top variables
    var position_top, body_top, position_bottom, windowHeight;
    $(document).scroll(function() {
      // handle the scroll to top functionality
      body_top = $(window).scrollTop();
      position_top = $('.page__content').offset().top;
      position_bottom = position_top + $('.page__content').height() - body_top;
      windowHeight = $(window).height();

      if (position_top <= body_top) {
        $('.back-to-top').fadeIn();
      } else {
        $('.back-to-top').fadeOut();
      }
      if (position_bottom <= windowHeight) {
        if ($('.back-to-top').hasClass('fixed') === true) {
          $('.back-to-top-placeholder').css('height', 0);
          $('.back-to-top').removeClass('fixed');
        }
      } else {
        if ($('.back-to-top').hasClass('fixed') === false) {
          $('.back-to-top-placeholder').css(
            'height',
            $('.back-to-top').outerHeight(),
          );
          $('.back-to-top').addClass('fixed');
        }
      }
    });

    /* setup style and scroll in toc for desktop */
    if ($(window).width() > 960) {
      // set show and hide toc button
      var tocJQ = $('.toc');
      var btnShowHideJQ = $('#btn-show-hde-toc');
      var btnImgShowHideJQ = $('#btn-show-hde-toc img');
      btnImgShowHideJQ.css({
        bottom: $(window).height() / 2,
        right:
          $(window).width() / 2 -
          $('.o-content').width() / 2 +
          tocJQ.width() / 2,
      });
      btnShowHideJQ.click(function() {
        tocJQ.toggle();
        if (tocJQ.css('display') === 'none') {
          $('.page__sidebar').hide();

          btnImgShowHideJQ.attr('src', btnImgShowHideJQ.data('retracted-src'));
          btnImgShowHideJQ.css('right', '10px');
        } else {
          $('.page__sidebar').show();

          btnImgShowHideJQ.attr('src', btnImgShowHideJQ.data('open-src'));
          btnImgShowHideJQ.css(
            'right',
            $(window).width() / 2 -
              $('.o-content').width() / 2 +
              tocJQ.width() / 2,
          );
        }
      });

      $(document).scroll(function() {
        /* dynamic styles */
        // fix the position of the toc when scrolling to main content if
        // ($(document).scrollTop() >= $('#long-doc-main-title').offset().top) {

        if ($(document).scrollTop() >= $('.component--toc').offset().top) {
          $('#toc-main-heading').css('position', 'fixed');
          $('#toc-list').css('position', 'fixed');
        } else {
          $('#toc-main-heading').css('position', 'static');
          $('#toc-list').css('position', 'static');
        }

        // fix the position of the toc when the footer is visible

        if (
          $(document).scrollTop() + $(window).height() >=
          $('.related-links__container').offset().top
        ) {
          $('#toc-list').css('height', $(window).height() - 500);
        } else if (
          $(document).scrollTop() + $(window).height() >=
          $('footer').offset().top
        ) {
          $('#toc-list').css('height', $(window).height() - 200);
        } else {
          $('#toc-list').css('height', $(window).height() - 100);
        }

        /* Scroll sidebar when scrollong main contents */
        // get the curremt top visible element in the main content
        var topContentViewportElsArray = $.grep(
          $('.o-content h2, .o-content h3, .o-content h4'),
          function(item) {
            return (
              $(item).offset().top + $(item).height() >= $(document).scrollTop()
            );
          },
        );
        var topContentViewportEl = topContentViewportElsArray[0];
        var currentTocLinkJQ = $(
          '.toc a[href="#' + $(topContentViewportEl).attr('id') + '"]',
        );

        // set the link to the el above to be active
        $('.toc a').removeClass('current');
        currentTocLinkJQ.addClass('current');

        // detect if the current top el in o-content is also visible in sidebar
        var tocListJQ = $('.toc-list');

        var currentLinkLiTop = currentTocLinkJQ.parent().offset().top;
        var tocListTop = tocListJQ.offset().top;
        var currentLinkLiBottom =
          currentLinkLiTop + currentTocLinkJQ.parent().height();
        var tocListBottom = tocListTop + tocListJQ.height();

        // scroll to the link in the sidebar if it is not visible
        if (currentLinkLiTop < tocListTop) {
          tocListJQ.stop().animate(
            {
              scrollTop:
                tocListJQ.scrollTop() - (tocListTop - currentLinkLiTop),
            },
            1000,
            'swing',
          );
        }
        if (currentLinkLiBottom > tocListBottom) {
          tocListJQ.stop().animate(
            {
              scrollTop:
                tocListJQ.scrollTop() +
                tocListJQ.height() +
                (currentLinkLiBottom - tocListBottom),
            },
            1000,
            'swing',
          );
        }
      });
    }
  }
})(jQuery);

//Search Results per page handler listener for the results per page select
var setResultsPerPage = function() {
  var resultsPP_select = document.getElementsByClassName(
    'search_results_per_page',
  )[0];
  var newValue = resultsPP_select.value;
  var newURL = updateQueryString(
    'results_per_page',
    newValue,
    window.location.href,
  );
  window.location = newURL;
};

//updates a value of a key in the url
var updateQueryString = function(key, value, url) {
  if (!url) url = window.location.href;
  var re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi'),
    hash;

  if (re.test(url)) {
    if (typeof value !== 'undefined' && value !== null)
      return url.replace(re, '$1' + key + '=' + value + '$2$3');
    else {
      hash = url.split('#');
      url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
      if (typeof hash[1] !== 'undefined' && hash[1] !== null)
        url += '#' + hash[1];
      return url;
    }
  } else {
    if (typeof value !== 'undefined' && value !== null) {
      var separator = url.indexOf('?') !== -1 ? '&' : '?';
      hash = url.split('#');
      url = hash[0] + separator + key + '=' + value;
      if (typeof hash[1] !== 'undefined' && hash[1] !== null)
        url += '#' + hash[1];
      return url;
    } else return url;
  }
};

// rnswdi Design Improvements
// ON THIS PAGE H2 Navigation Links

const onThisPageAddLink = () => {
  
  const insidePageContainer = document.querySelector(".inside-page__container")

  if(insidePageContainer){
    const h2Nodes = Array.prototype.slice.call( document.querySelector(".inside-page__container").children);
    const onThisPageNav = document.querySelector(".on-this-page__nav")
    let h2Count = 0

    if(h2Nodes){
      
      const insideTabs = document.querySelectorAll(".tabs__tab-content");
      const insideAccordions = document.querySelectorAll(".accordion");

      if (insideTabs){
        for (let insideTab of insideTabs){

          const insideTabNodes = Array.prototype.slice.call(insideTab.children);
          // console.log(insideTabNodes)
          insideTabNodes.map(insideTabH2s => {
            const allTabH2s = insideTabH2s.querySelectorAll('h2')
              
            for ( let allTabH2 of allTabH2s){
              allTabH2.setAttribute("class", 'tab__headings')
            }

          })
        }
      }

      if (insideAccordions){
        for (let insideAccordion of insideAccordions){

          const insideAccordionNodes = Array.prototype.slice.call(insideAccordion.children);
          // console.log(insideAccordionNodes)
          insideAccordionNodes.map(insideAccH2s => {
            const allInsideAccH2s = insideAccH2s.querySelectorAll('h2')
              
            for ( let allInsideAccH2 of allInsideAccH2s){
              allInsideAccH2.setAttribute("class", `accordion__headings`)
            }
            
          })
        }
      }
      
      h2Nodes.map(pageH2s => {
        const h2headings = pageH2s.querySelectorAll("h2")
        
        h2headings.forEach(h2 => {
          
          let h2Anchor = document.createElement("a")
          h2Anchor.classList.add("on-this-page__links")
          
          if(!(h2.classList.contains("tab__headings")) && !(h2.classList.contains("accordion__headings")) && !(h2.classList.contains("component__heading")) && !(h2.classList.contains("icon-panels__heading"))){
            h2Anchor.innerHTML = h2.innerText.toString()
            ++h2Count
            
            h2.setAttribute("id", `heading`+ h2Count)
            h2Anchor.setAttribute("href", `#heading`+ h2Count)
            
            if(onThisPageNav) onThisPageNav.append(h2Anchor)
          }
        })
      })
    }
  }
  
}

onThisPageAddLink()