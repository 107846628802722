(function() {

    function classToggleAction(triggerId, targetId, className) {
        const trigger = document.getElementById(triggerId);
        const target = (typeof targetId == 'object') ? targetId : document.getElementById(targetId);

        trigger.addEventListener('click', (event) => {
            event.preventDefault();

            trigger.classList.toggle(className);
            target.classList.toggle(className);
        });
    }

    classToggleAction('js-nav-toggle', document.body, 'nav-expanded');

   // window.addEventListener('load' ,function(){
        $('.mobile_nav__sub_trigger').click(function(event){
            event.stopPropagation();
           openMobileSubMenu(this);
           return false;
        });
        
        $('.mobile_nav__link-back-container .mobile_nav__sub_trigger').click(function(event){
            event.stopPropagation();
            closeMobileSubMenu(this);
            return false;
        });
  //  })
    
    var openMobileSubMenu = function(obj){
        $('.nav.mobile-nav').addClass('is-open');
        $('.mobile-nav__item').removeClass('is-selected');
        $(obj).parents('.mobile-nav__item').addClass('is-selected');
    }
    
    var closeMobileSubMenu = function(obj){
        $('.nav.mobile-nav').removeClass('is-open');
    }

})();

(function() {

    const headerWrapper = $('.header > .page__wrapper');
    const mobileNav = $('.mobile-nav');
    var transBox;

    function translateBoxPosition() {
        if (window.innerWidth >= 960) {
            if ( headerWrapper.children('#google-translate__container').length != 0 ) return;
            
            transBox = mobileNav.children('#google-translate__container').detach();
            transBox.appendTo(headerWrapper);
    
        } else {
            if (mobileNav.children('#google-translate__container').length != 0 ) return;
    
            transBox = headerWrapper.children('#google-translate__container').detach();
            transBox.appendTo(mobileNav);
        }
    }

    $(document).ready(translateBoxPosition);
    $(window).on('resize', debounce(translateBoxPosition, 50) );

})();

// dev-rnswdi Design Improvements

const closeMobileMenu = () => {
    const webpageBody = document.querySelector("body")
    const closeMenuIcon = document.querySelector(".mobile-nav__close-menu")
    const newMobileNav = document.querySelector(".mobile-nav")
    const menuToggle = document.getElementById("js-nav-toggle")
    

    closeMenuIcon.addEventListener('click', function(){
        newMobileNav.classList.remove('is-open')
        webpageBody.classList.remove('nav-expanded')
        menuToggle.classList.remove('nav-expanded')
    })
}

closeMobileMenu();


const mobileMenuAccordion = () => {
    const mobileMenu = document.querySelectorAll(".mobile-nav__item")
    const mobileMenuAcc = document.querySelectorAll(".mobile-nav__acc")

    mobileMenuAcc.forEach( (mobileAcc, mobileAccIndex) => {
        mobileAcc.addEventListener('click', function(){
            mobileMenu.forEach((mobile, mobileIndex) => {
                if(mobileAccIndex !== mobileIndex){
                    mobile.classList.remove("active")
                }

                if(mobileAccIndex == mobileIndex){
                    mobile.classList.toggle("active")
                }
            })
        })
    }); 
}

mobileMenuAccordion();

const desktopMenu = () => {
    
    const menuParent = document.querySelectorAll(".header__menu-main-items")
    const menuDropdown = document.querySelectorAll(".header__menu-main-container")
    const menuChevron = document.querySelectorAll(".header__menu-main-chevron")
    const main = document.querySelector('.main')

    main.addEventListener('mouseup', function(event){
        menuParent.forEach( (rmvMenu) => {
            rmvMenu.classList.remove('active')
        })
        menuDropdown.forEach( (rmvDropdown) => {
            rmvDropdown.classList.add('hidden')
        })
        menuChevron.forEach( (revertChevron) => {
            revertChevron.classList.remove('active')
        })
    });

    menuParent.forEach( (main, mainIndex) => {
        main.addEventListener('click', function(){
            menuParent.forEach((underline, underlineIndex) => {
                if(mainIndex !== underlineIndex){
                    underline.classList.remove("active")
                }

                if(mainIndex == underlineIndex){
                    underline.classList.toggle("active")
                }
            })

            menuDropdown.forEach((dropdown, dropdownIndex) => {
                if(mainIndex !== dropdownIndex){
                    dropdown.classList.add("hidden")
                }

                if(mainIndex == dropdownIndex){
                    dropdown.classList.toggle("hidden")
                }
            })

            menuChevron.forEach((chevron, chevronIndex) => {
                if(mainIndex !== chevronIndex){
                    chevron.classList.remove("active")
                }

                if(mainIndex == chevronIndex){
                    chevron.classList.toggle("active")
                }
            })
        })
    }); 

}

desktopMenu();

const toggleMobileSearch = () => {
    const mobileHeaderSearch = document.querySelector(".header__mobile-search")
    const mobileSearchBar =  document.querySelector(".header__mobile-search-container")
    const searchIcon = document.querySelector(".search-magnifier-icon")
    const closeSearchIcon = document.querySelector(".search-close-icon")

    mobileHeaderSearch.addEventListener("click", function(){
        mobileSearchBar.classList.toggle("hidden")
        searchIcon.classList.toggle("hidden")
        closeSearchIcon.classList.toggle("hidden")
        
    })
}

toggleMobileSearch();

const sideContentDesktop = () => {
    const subMenuDropdown = document.querySelectorAll(".header__menu-dropdown")

    if(subMenuDropdown){
        subMenuDropdown.forEach( subMenu => {
            const bar = subMenu.querySelector(".header__menu-dropdown-bar")
            const feature = subMenu.querySelector(".header__dropdown-feature")
    
            subMenu.classList.add("no-side-content")
    
            if(bar && feature){
                subMenu.classList.add("side-content")
                subMenu.classList.remove("no-side-content")
            }
        }) 
    }
}

sideContentDesktop();