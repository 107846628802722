(function($) {
  var initSearchPage = function() {
    'use strict';

    var s_SearchFormHook = '.js-search-form', // The CSS hook to identify the search page <form>.
      s_SearchFiltersHook = '.js-search-filters', // The CSS hook to identify the area of the page that contains the filter inputs.
      s_SearchFiltersAllCheckboxHook = '.js-update-all-options-checkbox', // The CSS hook to identify the checkbox options that apply "All" of the respective facet's options.
      s_SearchFiltersTagHook = '.js-search-tag-link';

    // When a search page <option> is changed, e.g. number of ranks or sorting,  grab the new URL from the option value and refresh the current page.
    $('.js-update-results-sorting').on('change', updatePageParameters);
    $('.js-update-results-ranks').on('change', updatePageParameters);

    // When a search page <option> is changed on a "multi-select" search page, e.g. number of ranks or sorting, grab the new value from the option and update the page.
    $('.js-update-multi-facet-select').on(
      'change',
      { searchForm: s_SearchFormHook },
      onSelectOptionsSelection,
    );

    // When a search page facet checkbox is changed grab the new value from the input and update the page.
    $('.js-update-multi-facet-checkbox').on(
      'change',
      { searchForm: s_SearchFormHook, searchFilters: s_SearchFiltersHook },
      onCheckboxOptionsSelection,
    );

    // When a filter "tag" is clicked, update the search results.
    // Specific filter.
    $(s_SearchFiltersTagHook).on(
      'click',
      { searchFilters: s_SearchFiltersHook },
      onActiveFilterClick,
    );

    // Clear all of the applied filters.
    $('.js-clear-all-search-filters').on(
      'click',
      {
        searchFiltersAllHook: s_SearchFiltersAllCheckboxHook,
        searchFilterTagHook: s_SearchFiltersTagHook,
      },
      onRemoveAllFilterClick,
    );

    /**
     * Get URL parameters from the selected <option> on a <select> element
     * and then refresh the current page with the updated URL.
     *
     * @param {jQuery Event} evt A jQuery Event Object
     * @returns void
     */
    function updatePageParameters(evt) {
      evt.preventDefault();

      // Get the current page URL.
      var s_currentPageURL = window.location.href.split('?')[0];

      // Refresh the page.
      window.location.href = s_currentPageURL + $(evt.target).val();
    }

    /**
     * Add the sort options to the hidden form inputs and trigger form submission.
     *
     * @param {jQuery Event} evt A jQuery Event Object
     * @returns void
     */
    function onSelectOptionsSelection(evt) {
      evt.preventDefault();

      var $SearchForm = $(evt.data.searchForm),
        $SearchInput = {},
        s_InputName = $(evt.target).attr('name'),
        s_InputValue = $(evt.target).val(),
        s_InputMarkup = '';

      // Try to capture any matching hidden input.
      $SearchInput = $SearchForm.children('input[name="' + s_InputName + '"]');

      if ($SearchInput.length > 0) {
        // If the hidden input already exists, then update it's value.
        $SearchInput.val(s_InputValue);
      } else {
        // Otherwise, add the new input.
        s_InputMarkup =
          '<input type="hidden" name="' +
          s_InputName +
          '" value="' +
          s_InputValue +
          '"/>';
        $SearchForm.append(s_InputMarkup);
      }

      // Update the page.
      updateSearchResults(evt.data.searchForm);
    }

    /**
     * Add (or remove) the radio options to (or from) the hidden form inputs and trigger form submission.
     *
     * @param {jQuery Event} evt A jQuery Event Object that includes data for the filters hook
     * @returns void
     */
    function onCheckboxOptionsSelection(evt) {
      var $SearchForm = $(evt.data.searchForm),
        $SearchFilters = $(evt.data.searchFilters),
        $SearchInput = {},
        $This = $(this),
        s_InputName = $This.attr('name'),
        s_InputValue = $This.val(),
        b_InputIsChecked = $This.prop('checked'),
        s_InputMarkup = '';

      if (b_InputIsChecked) {
        if (s_InputValue === '') {
          // The user has selected the "All" option or is clearing all the currently applied filters.
          // Uncheck all other filter checkboxes of this same type.
          $SearchFilters
            .find('input[name="' + s_InputName + '"]:checked')
            .not($This)
            .each(function() {
              $(this).prop('checked', false);
            });

            $SearchForm.children('input[name="' + s_InputName + '"]',).remove();
        } else {
          // The user has specified a filter, so make sure the "All" option for this type is unchecked.
          $SearchFilters
            .find('input[name="' + s_InputName + '"]:checked')
            .each(function() {
              if ($(this).val() === '') {
                $(this).prop('checked', false);
              }
            });
        }

        // User has applied a new filter, add it to the hidden elements.
        s_InputMarkup =
          '<input type="hidden" name="' +
          s_InputName +
          '" value="' +
          s_InputValue +
          '"/>';
        $SearchForm.append(s_InputMarkup);
      } else {
        // User has removed a currently applied filter, so remove it from the hidden elements.
        // Since we can have multiple hidden fields with the same name, we need to match on name AND value.

        // Capture any matching hidden input(s).
        $SearchInput = $SearchForm.children('input[name="' + s_InputName + '"]',);

        $SearchInput.each(function() {
          removeElementByValue($(this), s_InputValue);
        });
      }

      // Update the page.
      updateSearchResults(evt.data.searchForm);
    }

    function removeElementByValue($TargetElement, value) {
      if ($TargetElement.val() === value) {
        $TargetElement.remove();
      }
    }

    /**
     * Update the results on the search page.
     *
     * @returns void
     */
    function updateSearchResults(formSelectorHook) {
      // Auto-submit the form.
      $(formSelectorHook).submit();
    }

    /**
     * Reset the results to page #1, by removing any set value for "start_rank".
     *
     * @returns void
     */
    function resetPagination(formSelectorHook) {
      $(formSelectorHook)
        .children('input[name="start_rank"]')
        .remove();
    }

    /**
     * Locate the corresponding filter in the filters menu, de-select it and
     * then trigger a change event on it so that the results are updated.
     *
     * Example expected markup:
     * <button class="js-click-hook" data-type="f.Type|type" data-value="Filter+Option+1">Filter Option 1</button>
     *
     * @param {jQuery Event} evt A jQuery Event Object that includes data for the filters hook
     * @returns void
     */
    function onActiveFilterClick(evt) {
      var $SearchFilters = $(evt.data.searchFilters),
        s_FilterType = $(this).attr('data-type'), // Get the filter "type" from the element attributes.
        s_FilterValue = $(this).attr('data-value'); // Get the selected filter value from the element attributes.

      var hasFilterFoundInFacet = false;
      $SearchFilters
        .find('input[name="' + s_FilterType + '"]')
        .each(function() {
          if ($(this).val() === s_FilterValue) {
            hasFilterFoundInFacet = true;
            $(this)
              .prop('checked', false)
              .trigger('change');
          }
        });

      if (!hasFilterFoundInFacet) {
        $('.js-search-form')
          .find('input[name="' + s_FilterType + '"]')
          .each(function() {
            if ($(this).val() === s_FilterValue) {
              $(this).val('');
            }
          });
        $('.js-search-form').submit();
      }

      // Remove the filter "tag".
      $(this).remove();
    }

    /**
     * Locate the "All" filters in the filters menu, select them and
     * then trigger a change event on them so that the results are updated.
     *
     * Example expected markup:
     * <button class="js-click-hook">Clear all</button>
     *
     * @param {jQuery Event} evt A jQuery Event Object that includes data for the "all" options filters hook and the filter "tags" hook
     * @returns void
     */
    function onRemoveAllFilterClick(evt) {
      var $SearchFiltersAllOption = $(evt.data.searchFiltersAllHook),
        $SearchFilterTags = $(evt.data.searchFilterTagHook);

      // $SearchFiltersAllOption.each(function() {
      //   $(this)
      //     .prop('checked', true)
      //     .trigger('change');
      // });

      $('.js-search-form')
        .find('input[name="f.Type|type"]')
        .each(function() {
          $(this).val('');
        });

      $('.js-search-form')
        .find('input[name="f.Topic|topic"]')
        .each(function() {
          $(this).val('');
        });
      $('.js-search-form').submit();

      // Remove all of the filter "tags".
      $SearchFilterTags.remove();
      $(this).remove();
    }
  };

  initSearchPage();
})(jQuery);
