(function () {

    const accordionComponents = document.querySelectorAll('.component.component-as-accordion')

    if (accordionComponents.length > 0) {
        Array
            .from(accordionComponents)
            .forEach(component => {
                const accordionToggler = component.querySelector('.component__heading--toggler');
                const accordionContent = component.querySelector('.component__list');

                accordionToggler.addEventListener('click', () => {
                    component
                        .classList
                        .toggle('active');
                });
            });
    }

    $('.component__link--share')
        .click(function () {
            if ($('.share__options').hasClass('share__options--active')) {
                $('.share__options').removeClass('share__options--active');
            } else {
                $('.share__options').addClass('share__options--active');
            }
        });

})();