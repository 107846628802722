const homebannerWrapper = document.querySelector(".homepage-banner__wrapper")
const homebannerContainer = document.querySelector(".homepage-banner__container")
const homebannerText = document.querySelector(".homepage-banner__text")
const homebannerImageContainer = document.querySelector(".homepage-banner__banner-img")
const homebannerImage = document.querySelector(".homepage-banner__img")

const bannerCheck = () => {

    if(homebannerImage){
        homebannerWrapper.classList.remove("text-only")
        homebannerText.classList.remove("text-only")
        homebannerImageContainer.classList.remove('hidden')
        homebannerContainer.classList.remove("text-only")
    }
    
    if(!homebannerImage) {
        if(homebannerImageContainer){
            homebannerWrapper.classList.add("text-only")
            homebannerText.classList.add("text-only")
            homebannerImageContainer.classList.add('hidden')
            homebannerContainer.classList.add("text-only")
        }
    }
}

bannerCheck();