(function ($) {
    'use strict';

    // var setupGlobalAlert = function () {

    //     var cookieValidity = 3; //days until cookie expires

    //     // show ad if cookie does not exist
    //     if (!cookieExist("global-site-alert")) 
    //         $("#global-alert").addClass('global-alert--enabled');
        
    //     $('.global-alert__btn-close')
    //         .click(function (e) {
    //             e.preventDefault();
    //             $(this)
    //                 .closest('.global-alert')
    //                 .removeClass('global-alert--enabled');
    //             setCookie(cookieValidity, "global-site-alert");
    //         });

    //     function cookieExist(title) {
    //         if ($.cookie(title) === null || $.cookie(title) === "" || $.cookie(title) === "null" || $.cookie(title) === undefined) {
    //             /* no cookie found */
    //             return false;
    //         } else {
    //             console.log('cookie is set, no ad this time');
    //             return true;
    //         }
    //     }
    //     function setCookie(days, title) {
    //         var date = new Date();
    //         var minutes = 60 * 24 * days; //3 days
    //         date.setTime(date.getTime() + (minutes * 60 * 1000));
    //         /* set cookie */
    //         $.cookie(title, "close", {expires: date});
    //     }
    // }

    // setupGlobalAlert();

    const newGlobalAlert = function(){

        // %%%%%%%%%%% Site alert functions %%%%%%%%%%%

        // hashing function
        // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
        let alertCookieLengthInHours = parseFloat($('.site-alert__cookie-timeout').html());

        async function digestMessage(message) {
            const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
            const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
            return hashHex;
        }
    
    
        function setCookie(cookieKey, cookieValue, expireHours) {
            let cookie = cookieKey + "=" + cookieValue;
            const d = new Date();
            d.setTime(d.getTime() + (expireHours*60*60*1000));
            let expires = "expires=" + d.toUTCString();
        
            document.cookie = cookie + ";" + expires + ";path=/";
        }

        function readCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        }
    
        function delete_cookie(name) {
            document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    
        function checkSiteAlertCookie() {                                       
            let currentAlertMessage = $('.global-alert__message').html();

            let prevDismissedMessageHash = readCookie('rnsw_sitealertdismissedmessage');
        
            digestMessage(currentAlertMessage).then((result)=>{
                if (result === prevDismissedMessageHash) {      // compare current message and last closed message hashes
                    return;
                } else {
                    $("#global-alert").addClass('global-alert--enabled');
                    delete_cookie('rnsw_sitealertdismissedmessage');
                }
            });
        }

        function setAlertCookie() {                                 
            let currentAlertMessage = $('.global-alert__message').html();
        
            digestMessage(currentAlertMessage).then((res) => {
                setCookie("rnsw_sitealertdismissedmessage", res, alertCookieLengthInHours);
            })
        }

        // close alert
        $('.global-alert__btn-close').on('click', function (e) {
            $(this).closest('.global-alert').removeClass('global-alert--enabled');
            setAlertCookie();
        })
    
        if (crypto.subtle !== undefined) {
            checkSiteAlertCookie();
        }
        
    }

    newGlobalAlert();

}(jQuery));