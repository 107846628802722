(function() {

    const quickLinksColumns = document.querySelectorAll('.quick-links__column');

    if (quickLinksColumns.length > 0) {
        Array.from(quickLinksColumns).forEach(column => {
            const trigger = column.querySelector('.quick-links__heading--toggle');
            trigger.addEventListener('click', () => column.classList.toggle('active') );
        });
    }

})();