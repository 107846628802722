// (function ($) {     'use strict'; $('.consultations__graph').each(function
// (pos, ele) {         var $bar = $(this).find('.progressbar');         var
// $conGraph = $(this);         var barId = "bar-" + pos; console.log($bar);
// $bar.attr('id', barId);         var startDate =
// moment($conGraph.attr('data-start'), 'YYYY, MM, DD, hh, mm, ss');         var
// endDate = moment($conGraph.attr('data-end'), 'YYYY, MM, DD, hh, mm, ss'); var
// today = moment();         var span = (endDate - startDate) / 3600000; var
// timeLeft = (endDate - today) / 3600000;         var progress = (span -
// timeLeft) / span;         progress = progress > 1             ? 1 : progress;
//         console.log(progress);         $bar.waypoint({  handler: function (d)
// {                 var thisColor = this.options.element.dataset.color,
// thisProgress = this.options.element.dataset.progress,         element =
// this.element || undefined;                 if (element !== undefined &&
// !jQuery(element).hasClass('is-finished')) { var bar = new
// ProgressBar.Line('#' + barId, { strokeWidth: 4,                   easing:
// 'easeInOut',  duration: 1400,                         color: thisColor,
// trailColor: '#dedede',                         trailWidth: 1, svgStyle: {
// width: '100%',              height: '100%'       }          });
// jQuery(element).addClass('is-finished'); bar.animate(thisProgress);
// console.log('ran');         }     },            offset: 'bottom-in-view'  });
// }); }(jQuery));

(function ($) {
    'use strict';

    $.Squiz = {};

    $.Squiz = (function () {

        // Functions
        var self = {
            mqListener: function () {
                /*
                 * mqListener - http://zerosixthree.se/detecting-media-queries-with-javascript/
                 * How to use
                 var _mqListener = function() {
                 $(window).on('breakpoint-change', function(e, breakpoint) {
                 console.log(breakpoint);
                 });
                 };
                 *
                 */
                var resizeListener = function () {
                    $(window)
                        .on('resize orientationchange load', function () {
                            // Regexp for removing quotes added by various browsers
                            currentBreakpoint = afterElement
                                .getPropertyValue('content')
                                .replace(/^["']|["']$/g, '');

                            if (currentBreakpoint !== lastBreakpoint) {
                                $(window).trigger('breakpoint-change', currentBreakpoint);
                                lastBreakpoint = currentBreakpoint;
                            }
                        });
                };

                function init() {
                    if (!afterElement) {
                        return;
                    }

                    // call func
                    resizeListener();
                }

                init();
            },
            initFunc: function () {
                var jsEnabledFunc = function (varObj) {
                    if ($('body').hasClass('js-enabled')) {
                        // Show Live Chat menu in top menu varObj.$menuLiveChat.show();
                    }
                };

                var initIE9 = function (varObj) {
                    varObj
                        .$breadcrumbsInside
                        .css('transform', 'rotate(90deg)');
                };

                function init() {
                    var varObj = {
                        // $menuLiveChat: $('.topmenu__livechat'),
                        $breadcrumbsInside: $('.breadcrumbs__inside')
                    };

                    // Call func
                    jsEnabledFunc(varObj);
                    initIE9(varObj);
                }

                init();
            },

            progressbar: function () {
                var doProgressbar = function (varObj) {
                    var jQuery = $;
                    var dateFormat = 'DD-MM-YYYY hh:mm:ss';
                    var $progressbars = varObj.$progressbar;

                    $progressbars.each(function (pos, ele) {
                        var $bar = $(this);
                        var barId = "bar-" + pos;

                        $bar.attr('id', barId);

                        var startDate = moment($bar.attr('data-start'), 'YYYY, MM, DD, hh, mm, ss');
                        var endDate = moment($bar.attr('data-end'), 'YYYY, MM, DD, hh, mm, ss');
                        var today = moment();

                        var span = (endDate - startDate) / 3600000;
                        var timeLeft = (endDate - today) / 3600000;
                        var progress = (span - timeLeft) / span;

                        progress = progress > 1
                            ? 1
                            : progress;

                        if ($('.lt-ie10').length) {
                            var barColor = $bar.attr('data-color'),
                                barSize = $bar.attr('data-progress') * 100,
                                barEle = '<div class="progressbar__ie9" style="background-color: ' + barColor + '; width: ' + barSize + '%;"></div>';

                            $bar.prepend(barEle);
                        } else {

                            $bar.waypoint({
                                handler: function (d) {
                                    var thisColor = this.options.element.dataset.color,
                                        thisProgress = this.options.element.dataset.progress,
                                        element = this.element || undefined;

                                    if (element !== undefined && !jQuery(element).hasClass('is-finished')) {
                                        var bar = new ProgressBar.Line('#' + barId, {
                                            //strokeWidth: 4,
                                            easing: 'easeInOut',
                                            duration: 1400,
                                            color: thisColor,
                                            trailColor: '#888',
                                            trailWidth: 1,
                                            svgStyle: {
                                                width: '100%',
                                                height: '100%'
                                            }
                                        });

                                        jQuery(element).addClass('is-finished');
                                        bar.animate(thisProgress);
                                    }
                                },
                                offset: 'bottom-in-view'
                            });
                        }

                    });

                };

                function init() {
                    var varObj = {
                        $progressbar: $('.progressbar')
                    };

                    doProgressbar(varObj);
                }

                init();
            },

            countdown: function() {
              var doCountDown = function(varObj) {
                var jQuery = $,
                    dateFormat = 'DD-MM-YYYY hh:mm:ss',
                    $countdowns = varObj.$countdown;

                $countdowns.each(function(index, elem) {
                  var $countdown = $(this),
                      endDate = moment($countdown.attr('data-end'), 'YYYY, MM, DD, hh, mm, ss'),
                      today = moment(),
                      timeLeft = Math.ceil((endDate - today) / 3600000 / 24);

                  timeLeft = timeLeft > 0 ? timeLeft : 0;

                  $countdown.find('strong').each(function (pos, ele) {
                      var $counter = $(this);
                      $counter.prop('Counter',0).animate({
                          Counter: timeLeft
                      }, {
                          duration: 1400,
                          easing: 'swing',
                          step: function (now) {
                              $counter.text(Math.ceil(now));
                          }
                      });
                  });
                });
              }

              function init() {
                  var varObj = {
                      $countdown: $('.consultations__countdown')
                  };

                  doCountDown(varObj);
              }

              init();
            },

            consultations: function () {
                var mqListener = function (varObj) {
                    $(window)
                        .on('breakpoint-change', function (e, breakpoint) {
                            varObj.breakpoint = breakpoint;

                            // add is-no-active for submission accordion on large mq
                            if (varObj.breakpoint == 'mq-large') {
                                varObj
                                    .$submissions
                                    .addClass('is-no-active');
                            } else {
                                varObj
                                    .$submissions
                                    .removeClass('is-no-active');
                            }
                        });
                };

                var dateMoment = function (varObj) {
                    varObj
                        .$consultationGraph
                        .each(function () {
                            var $this = $(this);

                            if ($this.hasClass('consultations__graph--landing')) {
                                var $dateRemaining = $this
                                        .prev()
                                        .find('.consultation__date-remaining'),
                                    $dateTotal = $this
                                        .prev()
                                        .find('.consultation__date-total'),
                                    $progressBar = $this.find('.progressbar');
                            } else {
                                var $dateRemaining = $this.find('.consultation__date-remaining'),
                                    $dateTotal = $this.find('.consultation__date-total'),
                                    $progressBar = $this.find('.progressbar');
                            }

                            var dateStartArray = moment($this.attr('data-start'), 'YYYY, MM, DD, hh, mm, ss'),
                                dateEndArray = moment($this.attr('data-end'), 'YYYY, MM, DD, hh, mm, ss'),
                                dateToday = moment(),
                                dateRemaining,
                                //ceil to show one day remaining on the last day
                                dateTotal = Math.ceil(dateEndArray.diff(dateStartArray, 'days', true)),
                                progressCount = 0,
                                progressCountData;

                            // progress 0 if didn't start yet; progress 100 if already finished
                            if (dateEndArray.diff(dateToday, 'days') < dateTotal) {
                                //calculate exact progress using floating value of days insted of rounded up
                                progressCount = Math.abs(dateStartArray.diff(dateToday, 'days', true) / dateEndArray.diff(dateStartArray, 'days', true) * 100) > 100
                                    ? 100
                                    : Math.abs(dateStartArray.diff(dateToday, 'days', true) / dateEndArray.diff(dateStartArray, 'days', true) * 100);
                                dateRemaining = dateEndArray.diff(dateToday, 'days')
                            } else {
                                dateRemaining = Math.ceil(dateEndArray.diff(dateStartArray, 'days', true));
                            }

                            progressCountData = progressCount / 100;
                            dateRemaining = dateRemaining > 0
                                ? dateRemaining
                                : 0;
                            $progressBar.html('<div class="consultations__progress-count">' + Math.floor(progressCount) + '%</div>').attr('data-progress', progressCountData);
                            $dateRemaining.html(dateRemaining);
                            $dateTotal.html(dateTotal);
                        });
                };

                function init() {
                    var varObj = {
                        $submissions: $('.submissions'),
                        $consultationGraph: $('.consultations__graph'),
                        breakpoint: ''
                    };

                    mqListener(varObj);
                    dateMoment(varObj);
                }

                init();
            }
        };

        return self;
    })();
})(jQuery);
$
    .Squiz
    .progressbar();
$
    .Squiz
    .countdown();
$
    .Squiz
    .consultations();
