(function () {

  var urlifyString = function () {
    $("[data-urlify]")
      .each(function () {

        $(this).html((anchorme($(this).html(), {
          attributes: [ // attributes is an array
            // an object that describes the attribute will produce: class="link"
            {
              name: "class",
              value: "no-ext-ic"
            }, {
              name: "target",
              value: "_blank"
            }
          ]
        })));

      });
  }

  urlifyString();

})();