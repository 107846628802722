/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
document
    .body
    .classList
    .remove('no-js');
document
    .body
    .classList
    .add('js-enabled');

// SVG Sprite polyfill for IE
if (window.svg4everybody) {
    window.svg4everybody();
}

$('.js-back-to-top').on('click', (event) => {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: 0
    }, 300);
});

/*
--------------------
Modules
--------------------
*/

/**
 * Copy Link
 */
const copyLinkFnInit = function () {
    var clipboard = new ClipboardJS('.btn-icon-copy');
    clipboard.on('success', function (e) {

        $(e.trigger).text('Copied!');

        setTimeout(function () {
            $(e.trigger).text('Copy link')
        }, 2000);

        e.clearSelection();
    });
}

copyLinkFnInit();

//ticket SDNSW-3612
// $("#toc").toc({content: "div.long-doc__content", headings: "h2,h3,h4"}); if
// access link not local, open it in a new window
// var externaliseLinks = function () {
//     var siteURL = new RegExp(location.host);
//     var links = $('.externalise-links a');
//     links.each(function () {
//         var link = $(this).attr('href');
//         if (!siteURL.test(link)) {
//             $(this).attr("target", "_blank");
//         }
//     });
// }

// externaliseLinks();
