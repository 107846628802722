(function ($) {
    var $slideshow = $('.carousel');

    $slideshow.each((index, carousel) => {
        const $carousel = $(carousel);

        $carousel.on('init', (event, slick) => {

            slick
                .$slider
                .append('<div class="carousel-controls"></div>');

            const controlsContainer = slick
                .$slider
                .find('.carousel-controls');

            slick
                .$dots
                .appendTo(controlsContainer);
            slick
                .$prevArrow
                .appendTo(controlsContainer);
            slick
                .$nextArrow
                .appendTo(controlsContainer);
        });

        $carousel.slick({
            dots: true,
            arrows: true,
            speed: 400,
            slide: '.carousel__slide',
            slidesToScroll: 1,
            slidesToShow: 1
        });
    })

}(jQuery));
