var checkShowMoreRequired = function (container, btn, itemThreshold) {

  if (container.querySelectorAll('.tile__list .tile__item').length <= itemThreshold) {
    btn
      .classList
      .add('hidden');
    btn.setAttribute('rel', 'hidden');
  } else {
    btn
      .classList
      .remove('hidden');
    btn.setAttribute('rel', 'visible');
  }
}

function checkHiddenAccessibility(elem) {
  const relExists = elem.getAttribute('rel');
  const ariaExpandedExists = elem.getAttribute('aria-expanded');

  if (!relExists) {
    var attr = document.createAttribute('rel');
    elem.setAttributeNode(attr);
  }
  if (!ariaExpandedExists) {
    var attr = document.createAttribute('aria-expanded');
    elem.setAttributeNode(attr);
  }
}

function showMoreResizeEvent(e) {
  setTimeout(function () {
    const tileContainers = document.querySelectorAll('.tile__container');

    if (tileContainers.length > 0) {
      Array
        .from(tileContainers)
        .forEach(function (container) {
          const btnToggler = container.querySelector('.show-more__button');

          if (!btnToggler) 
            return;
          
          var windowSize = window.innerWidth;

          if (windowSize >= 960) {
            checkShowMoreRequired(container, btnToggler, 8);
          }
          if (windowSize < 960 && windowSize >= 600) {
            checkShowMoreRequired(container, btnToggler, 6);
          }
          if (windowSize < 600) {
            checkShowMoreRequired(container, btnToggler, 4);
          }

        });
    }
  }, 250);
}

function autoExpandSectionLinks() {
  var windowSize = window.innerWidth;

  //get section links

  var sectionLinks = $(".tile__list .tile__link");

  var activePosition = null;

  //cycle through links and find position of active link
  sectionLinks.each(function (index, link) {
    if ($(link).hasClass("active")) {
      activePosition = index + 1;
      return false;
    }
  });

  if (windowSize >= 960 && activePosition > 8) {
    $(".show-more__button").trigger("click");
  }
  if (windowSize < 960 && windowSize >= 600 && activePosition > 6) {
    $(".show-more__button").trigger("click");
  }
  if (windowSize < 600 && activePosition > 4) {
    $(".show-more__button").trigger("click");
  }
}

function toggleTabToAccordionEvent(e) {
  setTimeout(function () {
    const tabContainers = document
      .querySelectorAll('.tabs.uber-accordion')
      .length > 0
      ? document.querySelectorAll('.tabs.uber-accordion')
      : document.querySelectorAll('.tabs-converted.uber-accordion');

    if (tabContainers.length > 0) {
      Array
        .from(tabContainers)
        .forEach(container => {
          var windowSize = window.innerWidth,
            links = container
              .classList
              .contains('tabs-converted')
              ? container.querySelectorAll('.accordion__link')
              : container.querySelectorAll('.tabs__link'),
            targets = container
              .classList
              .contains('tabs-converted')
              ? container.querySelectorAll('.accordion__target')
              : container.querySelectorAll('.tabs__target'),
            accordionItems = container.querySelectorAll('.accordion__item'),
            tabsList = container.querySelector('.tabs__tab-list'),
            tabsContent = container.querySelector('.tabs__tab-content'),
            tabsItems = container.querySelectorAll('.tabs__item');

          if (windowSize < 600 && !container.classList.contains('social-tabs')) {
            if (!container.classList.contains('tabs-converted')) {
              container
                .classList
                .remove('tabs');
              container
                .classList
                .add('tabs-converted');
              container
                .classList
                .add('accordion');

              if (accordionItems.length > 0) {
                // Append the items to the existing containers
                for (var a = 0; a < accordionItems.length; a++) {
                  links[a]
                    .classList
                    .remove('tabs__link');
                  links[a]
                    .classList
                    .add('accordion__link');
                  targets[a]
                    .classList
                    .remove('tabs__target');
                  targets[a]
                    .classList
                    .add('accordion__target');
                  accordionItems[a]
                    .querySelector('h2')
                    .append(links[a]);
                  accordionItems[a].append(targets[a]);
                  accordionItems[a]
                    .classList
                    .remove('hidden');
                }
                reconfigureUberAccordion('.tabs-converted', '.accordion__link', true, true);
                tabsList
                  .classList
                  .add('hidden');
                tabsContent
                  .classList
                  .add('hidden');
              } else {
                // Create containers for the items to be appended to
                for (var b = 0; b < links.length; b++) {
                  links[b]
                    .classList
                    .remove('tabs__link');
                  links[b]
                    .classList
                    .add('accordion__link');
                  targets[b]
                    .classList
                    .remove('tabs__target');
                  targets[b]
                    .classList
                    .add('accordion__target');
                  var accordionItem = createElem('div', 'accordion__item');
                  accordionItem.append(createElem('h2'));
                  accordionItem
                    .querySelector('h2')
                    .append(links[b]);
                  accordionItem.append(targets[b]);
                  container.append(accordionItem);
                }
                reconfigureUberAccordion('.tabs-converted', '.accordion__link', true, true);
                tabsList
                  .classList
                  .add('hidden');
                tabsContent
                  .classList
                  .add('hidden');
              }
            }
          } else {
            if (container.classList.contains('tabs-converted')) {
              container
                .classList
                .remove('tabs-converted');
              container
                .classList
                .add('tabs');
              container
                .classList
                .remove('accordion');
              // Move accordions to tab containers
              for (var c = 0; c < accordionItems.length; c++) {
                links[c]
                  .classList
                  .add('tabs__link');
                links[c]
                  .classList
                  .remove('accordion__link');
                targets[c]
                  .classList
                  .add('tabs__target');
                targets[c]
                  .classList
                  .remove('accordion__target');
                tabsItems[c].append(links[c]);
                tabsContent.append(targets[c]);
                accordionItems[c]
                  .classList
                  .add('hidden');
              }
              reconfigureUberAccordion('.tabs', '.tabs__link');
              tabsList
                .classList
                .remove('hidden');
              tabsContent
                .classList
                .remove('hidden');
            }
          }
        });
    }
  }, 250);
}

function createElem(elemType, classes = '') {
  var elem = document.createElement(elemType);
  var attr = document.createAttribute('class');
  attr.value = classes;
  elem.setAttributeNode(attr);

  return elem;
}

function reconfigureUberAccordion(container, link, toggleVar = false, multipleVar = false) {
  // dont do this for the social tabs on the home page
  // if($(container).hasClass('social-tabs')  === false){
  $(container).UberAccordion({
    buttonClass: link,

    autoExpand: function () {
      return window.location.hash || '#tabs__target-1';
    },

    toggle: toggleVar,
    multiple: multipleVar,
    preventDefaultButton: true
  });
  // }
}(function () {

  const tileContainers = document.querySelectorAll('.tile__container');

  if (tileContainers.length > 0) {
    Array
      .from(tileContainers)
      .forEach(container => {
        const btnToggler = container.querySelector('.show-more__button');
        checkHiddenAccessibility(btnToggler);
        var checkInitialExpanded = container
          .classList
          .contains('show-all')
          ? true
          : false;
        btnToggler.setAttribute('aria-expanded', checkInitialExpanded);

        if (btnToggler == null) 
          return;
        
        btnToggler.addEventListener('click', () => {
          container
            .classList
            .toggle('show-all');
          var expanded = container
            .classList
            .contains('show-all')
            ? true
            : false;
          btnToggler.setAttribute('aria-expanded', expanded);

          if (expanded) {
            btnToggler.innerHTML = "Show less";
          } else {
            btnToggler.innerHTML = "Show all";
          }

        });

      });
  }

  window.addEventListener('resize', showMoreResizeEvent);
  window.addEventListener('resize', toggleTabToAccordionEvent);
  showMoreResizeEvent();
  toggleTabToAccordionEvent();
  autoExpandSectionLinks();

})();
